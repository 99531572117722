import axios from "axios";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { useForm } from "react-hook-form";
import { format } from "date-fns";
import { formatInTimeZone } from 'date-fns-tz';
import { React, createContext, useState, useEffect } from "react";
import { useCoordinates } from "../CoordinatesContext";
import InstantTab from "./SideMenuTabs/InstantTab";
import RangeTab from "./SideMenuTabs/RangeTab";
import GraphTab from "./SideMenuTabs/GraphTab";
import styles from "./SideForm.module.css";


function calculateBoxArea(coords) {
  let area = 0;

  if (coords.length > 2) {
    for (let i = 0; i < coords.length - 1; i++) {
      area += coords[i][0] * coords[i + 1][1] - coords[i + 1][0] * coords[i][1];
    }
    area = Math.abs(area / 2);
  }

  return area;
}

const MyTiff = createContext(null);

export default function SideMenu({
  setWeatherFile,
  setRampType,
  setRampScale,
  rampType,
  rampScale,
  setColorBlind,
  parameter,
  setParameter,
  opacity,
  setOpacity,
  selectedModel,
  setSelectedModel,
  setSubmitted,
  activeTabKey,
  setActiveTabKey,
  displayPopupImage,
}) {
  const now = new Date();
  now.setMinutes(0);
  now.setSeconds(0);
  now.setMilliseconds(0);

  const [coordinates, setCoordinates] = useCoordinates();
  const [raster, setRaster] = useState([]);
  const [forecastHour, setForecastHour] = useState(0); // forecast hour
  const [modelRun, setModelRun] = useState("NA"); // model run date
  const [tiffStats, setTiffStats] = useState([0, 0, 0]); // min max mean
  const [loading, setLoading] = useState(false);
  const [allModels, setAllModels] = useState({});
  const [hasShownAlert, setHasShownAlert] = useState(false);
  const [hasMadeDefaultRequest, setHasMadeDefaultRequest] = useState(false);
  const [selectedDate, setSelectedDate] = useState(now);
  const [increment, setIncrement] = useState(1);
  const [incrementUnit, setIncrementUnit] = useState("hours");

  // For graphs
  const MAX_AGGS_BOX_AREA_QUART_DEG = 8000;
  const MAX_AGGS_BOX_AREA_3KM = 300;

  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const tabTitleClass = (key) => {
    return key === activeTabKey
      ? styles.sideformTabSelected
      : styles.sideformTabUnselected;
  };

  const {
    register,
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      model: "gfs",
      start_date: now,
    },
  });

  useEffect(() => {
    if (modelRun !== "NA" && selectedDate) {
      calculateForecastHour(modelRun, selectedDate); // Recalculate forecast hour when modelRun changes
    }
  }, [modelRun, selectedDate]); // Only trigger this effect when modelRun or selectedDate changes

  const handleSelect = (key) => {
    setActiveTabKey(key);
    setValue("tab", key);
  
    // Do not reset the model when switching tabs to allow each to retain its selection.
    // Only adjust the date based on the active tab.
    let startTime;
    if (key === "instant") {
      startTime = watch("instant_start_date") || now;
    } else if (key === "range") {
      startTime = watch("range_start_date") || now;
    } else if (key === "graph") {
      startTime = watch("graph_start_date") || now;
    }
    
    setSelectedDate(startTime);
  };

// Watch start_date in Range and Graph tabs
const instantStartDate = watch("instant_start_date");
const rangeStartDate = watch("range_start_date");
const graphStartDate = watch("graph_start_date");

useEffect(() => {
  let activeStartDate = selectedDate; // Default to selectedDate

  if (activeTabKey === "range") {
    activeStartDate = rangeStartDate || selectedDate;
  } else if (activeTabKey === "graph") {
    activeStartDate = graphStartDate || selectedDate;
  } else{
    activeStartDate = instantStartDate || selectedDate;
  }


  if (modelRun && activeStartDate) {
    calculateForecastHour(modelRun, activeStartDate); // Recalculate forecastHour whenever the start_date changes
  }
}, [rangeStartDate, graphStartDate, instantStartDate, modelRun, activeTabKey]);
  

  const calculateForecastHour = (modelRun, selectedDate) => {
    if (!modelRun || !selectedDate) return;

    // Extract the date and hour from the modelRun string (e.g., "2024-10-12 (13Z)")
    const runDate = modelRun.slice(0, 10);  // Extract the date part (first 10 characters)
    const runHourPart = modelRun.match(/\((\d{2})Z\)/);  // Extract the hour part from "(13Z)"
    const runHour = runHourPart ? parseInt(runHourPart[1], 10) : 0;  // Get the hour, default to 0 if not found

    // Use the Date constructor with individual year, month, and day values for reliability
    const runDateParts = runDate.split("-"); // e.g., ["2024", "10", "12"]
    const modelRunDate = new Date(Date.UTC(
      parseInt(runDateParts[0]),  // year
      parseInt(runDateParts[1]) - 1,  // month (0-based index in JS)
      parseInt(runDateParts[2]),  // day
      runHour,  // hour (from "13Z")
      0,  // minutes
      0  // seconds
    ));

    // Convert selectedDate to UTC for comparison
    const selectedDateUTC = new Date(selectedDate.toUTCString());

    // Calculate the difference in milliseconds and then convert to hours
    const millisecondsDifference = selectedDateUTC - modelRunDate;
    const hoursDifference = Math.floor(millisecondsDifference / (1000 * 60 * 60));  // Convert to hours

    // Update the forecastHour in the state, ensuring it is not NaN
    setForecastHour(isNaN(hoursDifference) ? 0 : hoursDifference);
};

  // Fetch the latest model run stats
  const fetchModelRunStats = () => {
    if (selectedModel) {
      axios
        .get(`${backendUrl}/api/model-run?model=${selectedModel}`)
        .then((response) => {
          const latestModelRun = response.data.latestModelRun; // Example: "2024-10-12 (06Z)"
          setModelRun(latestModelRun);
          setAllModels(response.data.modelRuns);
          // calculateForecastHour(latestModelRun, selectedDate); // Calculate forecastHour dynamically
        })
        .catch((error) => {
          console.error("Failed to fetch latest model run:", error);
        });
    }
  };

  useEffect(() => {
    if (selectedModel) {
      fetchModelRunStats(); // Fetch model run stats only when the model changes
    }
  }, [selectedModel]);

  // Set up an interval to fetch model run stats every minute
  useEffect(() => {
    const intervalId = setInterval(() => {
      fetchModelRunStats();
    }, 45000); // fetch every 45 seconds

    // Cleanup the interval when component unmounts
    return () => clearInterval(intervalId);
  }, [modelRun, selectedDate]);

  //This happens only once (default map) when the user first comes to the page, once modelRun updates
  useEffect(() => {
    if (!hasMadeDefaultRequest && modelRun !== "NA") {
      // Check if modelRun is updated and not "NA"
      if (modelRun !== "NA") {
        // Create a new date object that adjusts for the timezone offset
        const adjustedDate = formatInTimeZone(selectedDate, 'UTC', "yyyy-MM-dd HH:00:00");

        // Define the default weather request
        const defaultWeatherRequest = {
          location: [
            [
              [-151.56, 9.86],
              [-151.56, 58.69],
              [-44.35, 58.69],
              [-44.35, 9.86],
              [-151.56, 9.86],
            ],
          ],
          parameter: "combined",
          datetime: format(adjustedDate, "yyyy-MM-dd HH:00:00"),
          model: selectedModel,
          model_res: process.env.REACT_APP_API_KEY,
          forecast_hour: 0,
          modelRun: modelRun,
        };
        setParameter("combined");
        // Prepare the request options
        const options_download = {
          url: `${backendUrl}/api/instant`, // URL to your /api/basic endpoint
          method: "POST",
          responseType: "blob", // As per your requirement
          data: defaultWeatherRequest,
        };

        // Make the API request
        axios(options_download)
          .then(function (response) {
            setHasMadeDefaultRequest(true);
            // Handle the response, e.g., update the map with the received data
            const href = URL.createObjectURL(response.data);
            setWeatherFile(href); // Assuming setWeatherFile updates the map
            // setRaster(response.data);
            const latestModelRun = response.data.latestModelRun; 
            calculateForecastHour(latestModelRun, selectedDate);
            // setForecastHour(response.headers["x-forecast-hour"]);

            // setTiffStats(response.headers["x-stats"]);
            let min = response.headers["x-stats-min"];
            let max = response.headers["x-stats-max"];
            let mean = response.headers["x-stats-mean"];

            setTiffStats([min, max, mean]);

            let warning = response.headers["x-warning"];
            if (warning && warning !== "False") {
              alert(`Warning: ${warning}`); // Display the warning in an alert
            }
          })
          .catch(function (error) {
            console.error("Error while fetching default weather data:", error);
          });
      }
    }
  }, [modelRun]);

  useEffect(() => {
    setValue("tab", activeTabKey);
    if (activeTabKey === "graph") {
      setIncrement(1);
    }
  }, [activeTabKey, setValue]);

  const onSubmit = (data) => {
    const coords = JSON.parse(coordinates);

    const formatted_coords = [];

    if (Array.isArray(coords)) {
      coords.forEach((coord) => {
        const formatted = [
          Number(parseFloat(coord.lng).toFixed(2)),
          Number(parseFloat(coord.lat).toFixed(2)),
        ];
        formatted_coords.push(formatted);
      });
      formatted_coords.push(formatted_coords[0]);
    } else {
      const formatted = [
        Number(parseFloat(coords.lng).toFixed(2)),
        Number(parseFloat(coords.lat).toFixed(2)),
      ];
      formatted_coords.push(formatted);
    }

    let model,
      param,
      weather_request,
      api_url,
      utc_date,
      utc_start_date,
      utc_end_date,
      start_time,
      end_time;

    // const end_date = (format(new Date(data.start_date), 'yyyy-MM-dd HH:00:00'))
    let API_KEY = process.env.REACT_APP_API_KEY;

    switch (data.tab) {
      // lets me handle each tab differently

      case "range":
      case "graph":
        // Validate the size of the drawn box
        const area = calculateBoxArea(formatted_coords); // Implement this function to calculate the area of the box

        if (
          area > MAX_AGGS_BOX_AREA_3KM &&
          !hasShownAlert &&
          (data.range_model === "nam" || data.range_model === "hrrr")
        ) {
          setHasShownAlert(true);
          alert(
            "Drawn Weather Window is too large for 3 KM resolution aggregations. Please draw a smaller one."
          );
          return; // Prevent the form from submitting and the API call from being made
          break;
        } else if (area > MAX_AGGS_BOX_AREA_QUART_DEG && !hasShownAlert) {
          setHasShownAlert(true);
          alert(
            "Drawn Weather Window is too large for quarter degree resolution aggregations. Please draw a smaller one."
          );
          return; // Prevent the form from submitting and the API call from being made
          break;
        }

        setHasShownAlert(false);
        setSelectedDate(start_time)

        calculateForecastHour(modelRun, start_time)

        if (data.tab === "range") {
          model = data.range_model;
          param = data.range_parameter;
          start_time = data.range_start_date;
          end_time = data.range_end_date;
        } else if (data.tab === "graph") {
          model = data.graph_model;
          param = data.graph_parameter;
          start_time = data.graph_start_date;
          end_time = data.graph_end_date;
        } else {
          // Fallback to instant model
          model = data.instant_model;
          param = data.instant_parameter;
          start_time = data.instant_start_date;
        }

        if (model === "era5_monthly") {
          utc_start_date = formatInTimeZone(start_time, 'UTC', "yyyy-MM-01 00:00:00");
          utc_end_date = formatInTimeZone(end_time, 'UTC', "yyyy-MM-01 00:00:00");
        } else if (model === "era5_daily" || model === "gfs_daily") {
          utc_start_date = formatInTimeZone(start_time, 'UTC', "yyyy-MM-dd 00:00:00");
          utc_end_date = formatInTimeZone(end_time, 'UTC', "yyyy-MM-dd 00:00:00");
        } else {
          utc_start_date = formatInTimeZone(start_time, 'UTC', "yyyy-MM-dd HH:00:00");
          utc_end_date = formatInTimeZone(end_time, 'UTC', "yyyy-MM-dd HH:00:00");
        }
        // console.log(start_time, end_time)
        // console.log(utc_start_date, utc_end_date)

        weather_request = {
          location: [formatted_coords],
          parameter: param,
          start_time: utc_start_date,
          end_time: utc_end_date,
          model_res: API_KEY,
          modelRun: modelRun,
        };

        if (data.tab === "graph") {
          weather_request.step = increment; // Assume increment is fetched from the form data
          weather_request.stepUnit = incrementUnit; // Assume incrementUnit is fetched from the form data
          weather_request.model = data.graph_model;
        } else {
          weather_request.model = data.range_model;
        }

        api_url = `${backendUrl}/api/${data.tab}`;
        break;
      case "instant":
        // For era5 data we are only looking at monthly averages or totals so we don't need to worry about timezones
        if (data.instant_model === "era5_monthly") {
          utc_date = format(data.instant_start_date, "yyyy-MM-01 00:00:00");
        } else if (data.instant_model === "reanalysis_season") {
          if (parameter === "snow") {
            utc_date = format(data.instant_start_date, "yyyy-09-30 12:00:00");
          } else {
            utc_date = format(data.instant_start_date, "yyyy-01-01 00:00:00");
          }
        } else if (
          data.instant_model === "era5_daily" ||
          data.instant_model === "reanalysis_24"
        ) {
          utc_date = format(data.instant_start_date, "yyyy-MM-dd 00:00:00");

        } else {

          // Format the local_date to a string in the format you desire
          utc_date = formatInTimeZone(
            data.instant_start_date,   // The date you want to convert
            'UTC',                     // Target timezone
            "yyyy-MM-dd HH:00:00"      // Desired format
          );
        }
        // console.log(data.instant_start_date)
        // console.log(utc_date)
        param = data.instant_parameter;
        model = data.instant_model;

        weather_request = {
          location: [formatted_coords],
          parameter: param,
          datetime: utc_date,
          model: model,
          model_res: API_KEY,
          modelRun: modelRun,
        };

        api_url = `${backendUrl}/api/instant`;

        break;

      default:
        console.error(`Unexpected data.tab value: ${data.tab}`);
        break;
    }

    const options_download = {
      url: api_url, //your url
      method: "POST",
      responseType: "blob", // important
      data: weather_request,
    };

    setLoading(true);
    axios(options_download)
      .then(function (response) {
        setLoading(false);
        const contentType = response.headers["content-type"];

        setModelRun(response.headers["x-model-run"]);
        const latestModelRun = response.data.latestModelRun; 
        calculateForecastHour(latestModelRun, selectedDate);
        
        if (contentType === "image/png") {
          
          const imageHref = URL.createObjectURL(response.data);
          let min = response.headers["x-stats-min"];
          let max = response.headers["x-stats-max"];
          let mean = response.headers["x-stats-mean"];
          setTiffStats([min, max, mean]);

          let warning = response.headers["x-warning"];
          if (warning && warning !== "False") {
            alert(`Warning: ${warning}`); // Display the warning in an alert
          }
          // Trigger the function to display the image in a popup
          displayPopupImage(imageHref);
        } else if (contentType === "image/tiff") {
          const href = URL.createObjectURL(response.data);

          let min = response.headers["x-stats-min"];
          let max = response.headers["x-stats-max"];
          let mean = response.headers["x-stats-mean"];
          setTiffStats([min, max, mean]);

          let warning = response.headers["x-warning"];
          if (warning && warning !== "False") {
            alert(`Warning: ${warning}`); // Display the warning in an alert
          }

          MyTiff.Provider = href;
          setWeatherFile(href);
          setRaster(response.data);
        }
      })
      .catch(function (error) {
        setLoading(false);
        console.error(error);
      });
  };

  return (
    <div className={styles.sideform_container}>
      <div
        className={`${
          activeTabKey === "instant"
            ? styles.sideform_instant
            : activeTabKey === "graph"
            ? styles.sideform_graph
            : styles.sideform_range
        }`}
      >
        <div className={styles.tabsContainer}>
          <Tabs defaultActiveKey="instant" id="menu-tabs" onSelect={handleSelect}>
            <Tab
              eventKey="instant"
              title={<span className={tabTitleClass("instant")}>Instant</span>}
            >
              <InstantTab
                register={register}
                control={control}
                handleSubmit={handleSubmit}
                onSubmit={onSubmit}
                errors={errors}
                setValue={setValue}
                selectedModel={selectedModel}
                setSelectedModel={setSelectedModel}
                modelRun={modelRun}
                setModelRun={setModelRun}
                allModels={allModels}
                forecastHour={forecastHour}
                tiffStats={tiffStats}
                now={now}
                activeTabKey={activeTabKey}
                loading={loading}
                setParameter={setParameter}
                setSubmitted={setSubmitted}
                parameter={parameter}
                setRampType={setRampType}
                setRampScale={setRampScale}
                rampType={rampType}
                rampScale={rampScale}
                setColorBlind={setColorBlind}
                opacity={opacity}
                setOpacity={setOpacity}
                increment={increment}
                setIncrement={setIncrement}
                incrementUnit={incrementUnit}
                setIncrementUnit={setIncrementUnit}
              />
            </Tab>
            <Tab
              eventKey="range"
              title={<span className={tabTitleClass("range")}>Range</span>}
            >
              <RangeTab
                register={register}
                control={control}
                handleSubmit={handleSubmit}
                onSubmit={onSubmit}
                errors={errors}
                setValue={setValue}
                selectedModel={selectedModel}
                setSelectedModel={setSelectedModel}
                modelRun={modelRun}
                setModelRun={setModelRun}
                allModels={allModels}
                forecastHour={forecastHour}
                tiffStats={tiffStats}
                now={now}
                activeTabKey={activeTabKey}
                loading={loading}
                setParameter={setParameter}
                setSubmitted={setSubmitted}
                parameter={parameter}
                setRampType={setRampType}
                setRampScale={setRampScale}
                rampType={rampType}
                rampScale={rampScale}
                setColorBlind={setColorBlind}
                opacity={opacity}
                setOpacity={setOpacity}
                increment={increment}
                setIncrement={setIncrement}
                incrementUnit={incrementUnit}
                setIncrementUnit={setIncrementUnit}
              />
            </Tab>
            <Tab
              eventKey="graph"
              title={<span className={tabTitleClass("graph")}>Graph</span>}
            >
              <GraphTab
                register={register}
                control={control}
                handleSubmit={handleSubmit}
                onSubmit={onSubmit}
                errors={errors}
                setValue={setValue}
                selectedModel={selectedModel}
                setSelectedModel={setSelectedModel}
                modelRun={modelRun}
                setModelRun={setModelRun}
                allModels={allModels}
                forecastHour={forecastHour}
                tiffStats={tiffStats}
                now={now}
                activeTabKey={activeTabKey}
                loading={loading}
                setParameter={setParameter}
                setSubmitted={setSubmitted}
                parameter={parameter}
                setRampType={setRampType}
                setRampScale={setRampScale}
                rampType={rampType}
                rampScale={rampScale}
                setColorBlind={setColorBlind}
                opacity={opacity}
                setOpacity={setOpacity}
                increment={increment}
                setIncrement={setIncrement}
                incrementUnit={incrementUnit}
                setIncrementUnit={setIncrementUnit}
              />
            </Tab>
          </Tabs>
        </div>
      </div>
    </div>
  );
}
export { MyTiff };
